import React from 'react'
import ThreeSixty from './React360'
import './App.css';
import Logo from './biorev360-logo.png'
import HomeIcon from './otd.jpg'
function App() {
	return (
		<div className="App">
			<ThreeSixty
				amount={36}
				imagePath="https://biorevstore.nyc3.cdn.digitaloceanspaces.com/otd/"
				fileName="{index}.jpg?v1"
				spinReverse={false}
				logoUrl={Logo}
				logoAlt='Biorev360'
				homeImg={HomeIcon}
				homeName="Home Design"
				homeText="By OTD"
				buttonClass="brv360-footer"
			/>
		</div>
	);
}

export default App;
